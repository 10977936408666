import {Modal} from 'bootstrap'
import Swiper, { Navigation, Pagination, Mousewheel } from 'swiper';
import Cookies from 'js-cookie'


const swiper = new Swiper('.fullscreen', {
    // configure Swiper to use modules
    modules: [Navigation, Pagination, Mousewheel],
    direction: 'vertical',
    slidesPerView: 1,
    speed: 1000,
    longSwipes: false,
    mousewheel: {
        thresholdDelta: 1,
    },
    pagination: {
        el: '.fullscreen .swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
});
swiper.on('slideChange', function () {
    const elements = Array.from(this.slides[this.realIndex].querySelectorAll('.animate__animated'))
    const stugging = elements.length == this.slides[this.realIndex].querySelectorAll('.delay').length
    elements.forEach((el, i) => {
        el.classList.add(el.dataset.animateIn)
        if (stugging) {
            console.log(stugging)
            el.style.animationDelay =  `${i*0.5}s`;
        }
    })
    // console.log(this);
});
swiper.on('beforeTransitionStart', function () {
    try {
        const elements = Array.from(
        this.slides[this.previousIndex].querySelectorAll(".animate__animated")
        );
        elements.forEach((el) => {
            el.classList.add(el.dataset.animateOut)
            
        });
    } catch (error) {
        console.log(error)
    }
});
swiper.on('slideChangeTransitionEnd', function () {
    try {
        const elements = Array.from(
            this.slides[this.previousIndex].querySelectorAll(".animate__animated")
        );
        elements.forEach((el) => {
            el.classList.remove(el.dataset.animateIn);
            el.classList.remove(el.dataset.animateOut);
        });
    } catch (error) {
        console.log(error)
    }
});

const products = new Swiper('.products', {
    // configure Swiper to use modules
    modules: [Navigation, Pagination, Mousewheel],
    slidesPerView: 1,
    speed: 1000,
    loop: true,
    longSwipes: false,
    mousewheel: {
        thresholdDelta: 1,
    },
    pagination: {
        el: '.products .swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    navigation: {
        nextEl: '.products .swiper-button-next',
        prevEl: '.products .swiper-button-prev',
    },
});

document.addEventListener('submit', async (e) => {
    e.preventDefault()
    const form = e.target
    const responseEl = form.querySelector('.form-response')
    grecaptcha.ready(function() {
        grecaptcha.execute('6LcA3GQjAAAAAIOPmdyCjhRSuVbayIM7eLb6P63p', {action: 'submit'}).then(async function(token) {
            form.elements['g-recaptcha-token'].value = token
            let body = new FormData(form)
            let response = await fetch('/api/mail.php', { method: 'POST', body: body })
            const data = await response.json()
            responseEl.textContent = data.message
            if (form.classList.contains('collaboration-form') && data.code == 200) {
                Cookies.set('showCollaborationPopup', '1', { expires: 14, path: '' })
            }
            setTimeout(() => {
                Array.from(form.querySelectorAll('input, textarea')).forEach((el) => { el.value = '' })
                responseEl.textContent = ''
            }, 3000)
           
        });
    });

})

const collaborationModal = new Modal('#collaborationModal')
collaborationModal._element.addEventListener('hide.bs.modal', () => {
    Cookies.set('showCollaborationPopup', '1', { expires: 14, path: '' })
})
if (Cookies.get('showCollaborationPopup') !== '1') {
    setTimeout(() => {
        collaborationModal.show()
    }, 15000)
}